export const sortListingsDropdown = [
  {
    field: "sale_price",
    label: "Price: Low to High",
    direction: 1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "sale_price",
    label: "Price: High to Low",
    direction: -1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "createdAt",
    label: "Created: Newest First",
    direction: 1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "createdAt",
    label: "Created: Oldest First",
    direction: -1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "nft.favorites",
    label: "Most Popular",
    direction: -1,
    icon: <i className="fas fa-heart" />,
  },
];

export const sortAuctionsDropdown = [
  {
    field: "sale_price",
    label: "Bid Price: Low to High",
    direction: 1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "sale_price",
    label: "Bid Price: High to Low",
    direction: -1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "createdAt",
    label: "Created: Newest First",
    direction: 1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "createdAt",
    label: "Created: Oldest First",
    direction: -1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "nft.favorites",
    label: "Most Popular",
    direction: -1,
    icon: <i className="fas fa-heart" />,
  },
  {
    field: "end_date",
    label: "Ending Soon",
    direction: 1,
    icon: <i className="fas fa-stopwatch" />,
  },
];

export const sortCollectionsDropdown = [
  {
    field: "volume",
    label: "Volume: High to Low",
    direction: -1,
    icon: <i className="fas fa-chart-bar" />,
  },
  {
    field: "volume",
    label: "Volume: Low to High",
    direction: 1,
    icon: <i className="fas fa-chart-bar" />,
  },
  {
    field: "floor",
    label: "Floor Price: High to Low",
    direction: -1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "floor",
    label: "Floor Price: Low to High",
    direction: 1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "createdAt",
    label: "Created: Newest First",
    direction: 1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "createdAt",
    label: "Created: Oldest First",
    direction: -1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "favorites",
    label: "Most Popular",
    direction: -1,
    icon: <i className="fas fa-heart" />,
  },
];

export const sortRentalsDropdown = [
  {
    field: "sale_price",
    label: "Price per day: Low to High",
    direction: 1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "sale_price",
    label: "Price per day: High to Low",
    direction: -1,
    icon: <i className="fas fa-dollar-sign" />,
  },
  {
    field: "createdAt",
    label: "Created: Newest First",
    direction: 1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "createdAt",
    label: "Created: Oldest First",
    direction: -1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "favorites",
    label: "Most Popular",
    direction: -1,
    icon: <i className="fas fa-heart" />,
  },
  {
    field: "end_date",
    label: "Ending Soon",
    direction: 1,
    icon: <i className="fas fa-stopwatch" />,
  },
];

export const sortDropdownBasic = [
  {
    field: "createdAt",
    label: "Created: Newest First",
    direction: -1,
    icon: <i className="fas fa-clock" />,
  },
  {
    field: "createdAt",
    label: "Created: Oldest First",
    direction: 1,
    icon: <i className="fas fa-clock" />,
  },
];
