import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getSearchFields } from "utils/formats";

import LayoutButtons from "components/button/LayoutButtons";
import RefreshButton from "components/button/RefreshButton";
import ListNFTCard from "components/cards/nfts/ListNFTCard";
import ListNFTSkeleton from "components/cards/nfts/ListNFTSkeleton";
import NFTCard from "components/cards/nfts/NFTCard";
import NFTSkeleton from "components/cards/nfts/NFTSkeleton";
import SearchBar from "components/inputs/SearchBar";
import Sorting from "components/inputs/Sorting";
import CardList from "components/layouts/explore/Lists/CardList";
import SellModal from "components/layouts/modal/SellModal";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSidebar } from "components/utils/SidebarProvider";

const CollectionNFTsPanel = ({ title, collection, attributes }) => {
  const user = useSelector((state) => state.user);
  const loading = useSelector((state) => state.search.loading);
  const settings = useSelector((state) => state.settings);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const { setSidebarData } = useSidebar();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [searchParams, _] = useSearchParams();
  const isOwner = user._id === modalShow.seller;
  const reduxState = useSelector((state) => state.search.listings);

  const orderedHeaders = ["Name", "Favorites"];

  useEffect(() => {
    setSidebarData([
      ...new Set([...getSearchFields({ ...collection, attributes, searchParams })]),
    ]);
  }, [collection, attributes]);

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting
            setSortParams={setSortParams}
            list={[
              {
                field: "createdAt",
                label: "Created: Newest First",
                direction: 1,
                icon: <i className="fas fa-clock" />,
              },
              {
                field: "createdAt",
                label: "Created: Oldest First",
                direction: -1,
                icon: <i className="fas fa-clock" />,
              },
              {
                field: "nft.favorites",
                label: "Most Popular",
                direction: -1,
                icon: <i className="fas fa-heart" />,
              },
            ]}
          />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={NFTSkeleton}
          CardComponent={NFTCard}
          ListSkeletonComponent={ListNFTSkeleton}
          ListCardComponent={ListNFTCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
        />
      </PaginationWrapper>
      {isOwner && <SellModal item={modalShow} onHide={() => setModalShow(false)} />}
    </div>
  );
};

export default CollectionNFTsPanel;
