import React from "react";
import { imageOnErrorHandler } from "utils/formats";

import CreatedBy from "components/utils/CreatedBy";

const Avatar = ({ creator, size, nolink, ...props }) => {
  const id = creator._id;
  const fallback = `https://gravatar.com/avatar/${id}?f=y&d=identicon&size=${size}`;

  return (
    <CreatedBy nolink={nolink} creator={creator} {...props}>
      <img
        src={creator.avatar_url ?? fallback}
        onError={(e) => imageOnErrorHandler(e, fallback)}
        className="avatar"
        {...props}
      />
    </CreatedBy>
  );
};

export default Avatar;
