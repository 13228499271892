import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  searchCollectionAuctions,
  searchCollectionListings,
  searchCollectionNFTs,
  searchCollectionRentals,
} from "redux/state/search";
import { reset } from "redux-form";
import styled from "styled-components";
import { findIndexByValue } from "utils/formats";

import CollectionAuctionsPanel from "components/layouts/explore/Panels/CollectionAuctionsPanel";
import CollectionListingsPanel from "components/layouts/explore/Panels/CollectionListingsPanel";
import CollectionNFTsPanel from "components/layouts/explore/Panels/CollectionNFTsPanel";
import CollectionRentalsPanel from "components/layouts/explore/Panels/CollectionRentalsPanel";
import { useRefreshSearch } from "components/utils/SearchProvider";
import { useSidebar } from "components/utils/SidebarProvider";
import { useTitle } from "components/utils/TitleProvider";

const FilterButton = styled.button`
  & {
    background-color: transparent;
    color: var(--primary-color2);
    border: none;
    border-radius: 0;
    padding: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: unset;

    :focus,
    :hover {
      background: var(--hover-color);
      border: none;
    }
  }
`;

const FilterIcon = styled.i`
  font-size: 14px;
`;

const CollectionDetailsTabs = ({ collection, attributes }) => {
  const { showFilter, setShowFilter } = useSidebar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTitle } = useTitle();
  const { link, ...params } = useParams();
  const { setSearchFunction } = useRefreshSearch();

  const dataTabs = [
    {
      title: "Listings",
      link: "listings",
      panel: (
        <CollectionListingsPanel
          title="Listings"
          collection={collection}
          attributes={attributes}
        />
      ),
      searchFunction: () => searchCollectionListings,
    },
    {
      title: "NFTs",
      link: "nfts",
      panel: (
        <CollectionNFTsPanel
          title="NFTs"
          collection={collection}
          attributes={attributes}
        />
      ),
      searchFunction: () => searchCollectionNFTs,
    },
    // {
    //   title: "Auctions",
    //   link: "auctions",
    //   panel: <CollectionAuctionsPanel title="Auctions" collection={collection} />,
    //   searchFunction: () => searchCollectionAuctions,
    // },
    // {
    //   title: "Rentals",
    //   link: "rentals",
    //   panel: <CollectionRentalsPanel title="Rentals" collection={collection} />,
    //   searchFunction: () => searchCollectionRentals,
    // },
  ];

  const [selectedTab, setSelectedTab] = useState(
    Math.max(findIndexByValue(dataTabs, "link", link), 0)
  );

  useEffect(() => {
    const tab = findIndexByValue(dataTabs, "link", link);
    if (tab !== -1 && tab !== selectedTab) {
      setSelectedTab(tab);
      setTitle(dataTabs[tab].title);
      setSearchFunction(dataTabs[tab].searchFunction);
    }
  }, [link]);

  useEffect(() => {
    setTitle(dataTabs[selectedTab].title);
    setSearchFunction(dataTabs[selectedTab].searchFunction);
    if (!link) {
      navigate(`/collection-details/${params.id}/${dataTabs[0].link}`, { replace: true });
    }
  }, []);

  const tabSelectHandler = (index) => {
    const link = dataTabs[index].link;
    dispatch(reset("sidebar-search"));
    navigate(`/collection-details/${params.id}/${link}`);
  };

  const handleFilter = () => {
    setShowFilter(true);
  };

  return (
    <div className={showFilter ? "col-box-83" : "fullWidth"}>
      <div className="flat-tabs items">
        <Tabs selectedIndex={selectedTab} onSelect={(index) => tabSelectHandler(index)}>
          <TabList>
            {!showFilter && (
              <FilterButton key="closed-filter" onClick={() => handleFilter()}>
                <FilterIcon className="fas fa-filter" />
              </FilterButton>
            )}
            {dataTabs.map((tab, index) => (
              <Tab key={index} disabled={tab.disabled}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          {dataTabs.map(({ panel }, index) => (
            <TabPanel key={index}>{panel}</TabPanel>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CollectionDetailsTabs;
