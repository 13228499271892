import React, { Children } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import LazyLoadImage from "components/utils/LazyLoadImage";

const GridRow = styled.div`
  padding: 0 2rem;
  display: grid;
  align-items: center;
  height: 88px;
  width: max-content;
  min-width: 100%;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  background: var(--primary-color);
  transition: all 0.4s ease;
  grid-template-columns: minmax(0px, 3fr) ${(props) =>
      `repeat(${Children.toArray(props.children).length - 1}, minmax(100px, 1fr))`};
  &:hover {
    background: var(--hover-color);
  }
`;

const NameImageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;

const RowImage = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50px;
  overflow: hidden;
`;

const RowName = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: -0.5px;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TextOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

const LeaderboardRow = ({ item, index, renderColumnFields }) => {
  const { _id, name, logo_image } = item;
  const to = `/collection-details/${_id}`;
  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <h6 className="mr-3">{index + 1}</h6>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={logo_image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "Unnamed Collection"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields(item)}
      </GridRow>
    </Link>
  );
};

export default LeaderboardRow;
