import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { favoriteNFT } from "redux/state/user";
import { setFeature } from "utils/api";

import EllipsisDropdown from "components/layouts/EllipsisDropdown";
import ToastPopup from "components/utils/ToastPopup";

const WishlistButton = ({ nft, className = "", onClick = undefined, ...props }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const favorited = useMemo(() => user.favorite_nfts?.includes(nft?._id), [user, nft]);

  const handleFavoriteNFT = () => {
    if (user._id) {
      dispatch(favoriteNFT(nft._id, user.favorite_nfts?.includes(nft?._id)));
      if (onClick) onClick();
    } else {
      ToastPopup("You must be connected in order to favorite NFTs", "error");
    }
  };

  if (user.role_id == 3) {
    // const { label, onClick, icon } = option;
    const list = [
      {
        label: "swiper-nfts",
        onClick: async (item) => {
          let res = await setFeature("swiper-nfts", item._id);
          ToastPopup(`NFT ${res.data}`);
        },
      },
      {
        label: `favourite (${nft?.favorites ?? "0"})`,
        onClick: handleFavoriteNFT,
      },
    ];

    return (
      <EllipsisDropdown
        style={{ position: "absolute", bottom: 0, right: 0 }}
        list={list}
        item={nft}
        {...props}
      />
    );
  }

  return (
    <div className={`meta-item ${className}`}>
      <span onClick={handleFavoriteNFT} className="wishlist-button">
        <span className={`fa-heart ${favorited ? "fa" : "far"}`} />
        <span>{nft?.favorites ?? "0"}</span>
      </span>
    </div>
  );
};

export default WishlistButton;
